<template>
    <v-card v-if="editProfile">
        <v-card-text>
            <v-text-field
                v-model="editProfile.name"
                :counter="30"
                :label="$t('profiles.attributes.name')"
                required
                outlined
                color="red"
            ></v-text-field>
        </v-card-text>
        <v-card-text>
            <users-selector v-model="editProfile.members" :label="$t('profiles.attributes.members')" outlined />
        </v-card-text>
        <v-card-text>
            <v-subheader>{{ $t('profiles.attributes.securities') }}</v-subheader>
            <v-container fluid class="pa-0">
                <v-row justify="center" no-gutters>
                    <v-col>
                        <v-checkbox
                            v-model="editProfile.security.sprintAccess"
                            :label="$t('profiles.attributes.security.sprintAccess')"
                        />
                    </v-col>
                    <v-col>
                        <v-checkbox
                            v-model="editProfile.security.packAccess"
                            :label="$t('profiles.attributes.security.packAccess')"
                        />
                    </v-col>
                    <v-col>
                        <v-checkbox
                            v-model="editProfile.security.packCreator"
                            :label="$t('profiles.attributes.security.packCreator')"
                        />
                    </v-col>
                    <v-col>
                        <v-checkbox
                            v-model="editProfile.security.timeAccess"
                            :label="$t('profiles.attributes.security.timeAccess')"
                        />
                    </v-col>
                    <v-col>
                        <v-checkbox
                            v-model="editProfile.security.knowledgeAccess"
                            :label="$t('profiles.attributes.security.knowledgeAccess')"
                        />
                    </v-col>
                </v-row>
                <v-row justify="center" no-gutters>
                    <v-col>
                        <v-checkbox
                            v-model="editProfile.security.analyticsAccess"
                            :label="$t('profiles.attributes.security.analyticsAccess')"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-text>
            <v-tabs centered>
                <v-tab class="justify-start" href="#profile-projects">
                    <v-icon left>mdi-package-variant</v-icon>
                    <span class="caption" v-text="$t('profiles.attributes.projects')" />
                </v-tab>
                <v-tab class="justify-start" href="#profile-views">
                    <v-icon left>mdi-format-list-bulleted</v-icon>
                    <span class="caption" v-text="$t('profiles.attributes.views')" />
                </v-tab>
                <v-tab class="justify-start" href="#profile-packs">
                    <v-icon left>mdi-toolbox</v-icon>
                    <span class="caption" v-text="$t('profiles.attributes.packs')" />
                </v-tab>

                <v-tab-item value="profile-projects">
                    <profile-projects :profile="editProfile" />
                </v-tab-item>
                <v-tab-item value="profile-views">
                    <profile-views :profile="editProfile" />
                </v-tab-item>
                <v-tab-item value="profile-packs">
                    <profile-packs :profile="editProfile" />
                </v-tab-item>
            </v-tabs>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn color="red" text class="mr-4" @click="cancelEdit">
                {{ $t('buttons.cancel') }}
            </v-btn>
            <v-btn color="success" class="mr-4" @click="save">
                {{ $t('profiles.actions.update') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import profileProjects from './profile/projects';
import profileViews from './profile/views';
import profilePacks from './profile/packs';
import usersSelector from '@/components/selectors/multiple/users';
import Clone from 'lodash/cloneDeep';

export default {
    name: 'settings-profile',
    data: () => ({
        editProfile: undefined,
    }),
    created() {
        this.findProfile(this.$route.params.profile_id);
    },
    components: {
        usersSelector,
        profileProjects,
        profileViews,
        profilePacks,
    },
    watch: {
        editingProfile: function (newProfile) {
            this.editProfile = Clone(newProfile);
            this.editProfile.members.sort();
        },
    },
    computed: {
        ...mapGetters(['profiles', 'editingProfile', 'projects']),
    },
    methods: {
        ...mapActions(['findProfile', 'addUserToProfile', 'removeUserToProfile', 'updateProfile']),

        cancelEdit() {
            this.editProfile = undefined;
            this.$router.push({ name: 'settings-profiles' });
        },
        save() {
            const oldMembers = this.editingProfile.members;
            const newMembers = this.editProfile.members;

            const membersToAdd = {
                id: this.editProfile.id,
                members: newMembers.filter((f) => !oldMembers.includes(f)),
            };
            const membersToRemove = {
                id: this.editProfile.id,
                members: oldMembers.filter((f) => !newMembers.includes(f)),
            };

            if (membersToAdd.members.length > 0) {
                this.addUserToProfile(membersToAdd);
            }
            if (membersToRemove.members.length > 0) {
                this.removeUserToProfile(membersToRemove);
            }

            this.updateProfile(this.editProfile);

            this.$router.push({ name: 'settings-profiles' });
        },
    },
};
</script>
