<template>
    <v-card v-if="profile" flat>
        <v-card-text>
            <v-data-table :headers="headers" :items="items" :items-per-page="-1">
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer />
                        <v-dialog v-model="dialog">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" small dark v-on="on">{{ $t('buttons.createView') }}</v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text v-if="!editView">
                                    <create-view v-on:create-view="addView" v-on:cancel="cancelEdit" />
                                </v-card-text>
                                <v-card-text v-if="editView">
                                    <edit-view
                                        :view="editView"
                                        v-on:update-view="updateView"
                                        v-on:delete-view="deleteView"
                                        v-on:cancel="cancelEdit"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    <v-icon small @click="deleteView(item)"> mdi-delete </v-icon>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import createView from '@/views/views/create';
import editView from '@/views/views/edit';
import SortBy from 'lodash/sortBy';

export default {
    name: 'settings-profile-views',
    data: () => ({
        dialog: false,
        editView: undefined,
    }),
    components: {
        createView,
        editView,
    },
    props: {
        profile: {
            required: true,
        },
    },
    computed: {
        ...mapGetters(['profiles', 'editingProfile', 'projects']),
        formTitle() {
            return this.editView ? this.$t('title.editView') : this.$t('title.newView');
        },
        headers() {
            return [
                {
                    text: this.$t('views.attributes.label'),
                    sortable: true,
                    value: 'label',
                },
                {
                    text: this.$t('profiles.attributes.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        items() {
            if (this.profile) {
                return SortBy(this.profile.views, ['label'], ['asc']);
            }
            return [];
        },
    },
    methods: {
        ...mapActions(['addViewToProfile', 'updateViewToProfile', 'deleteViewToProfile']),
        addView(view) {
            const payload = {
                profile: this.profile.id,
                view: view,
            };

            this.addViewToProfile(payload);
            this.dialog = false;
        },
        updateView(view) {
            const payload = {
                profile: this.profile.id,
                view: view,
            };

            this.updateViewToProfile(payload);
            this.dialog = false;
        },
        deleteView(item) {
            const editView = {
                profile: this.profile.id,
                id: item.id,
            };
            this.deleteViewToProfile(editView);
        },

        editItem(item) {
            this.editView = item;
            this.dialog = true;
        },
        cancelEdit() {
            this.dialog = false;
            this.editView = undefined;
        },
    },
};
</script>
