<template>
    <v-card v-if="profile" flat>
        <v-card-text>
            <v-data-table :headers="headers" :items="items" :items-per-page="-1">
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer />
                        <v-dialog v-model="dialog" max-width="800px">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" small dark v-on="on">{{ $t('buttons.createProject') }}</v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <project-selector
                                        v-model="editProject.name"
                                        :ignored="projectsInProfile"
                                        :readonly="editProject.id !== undefined"
                                        type="name"
                                    />
                                    <roles-selector :label="$t('projects.roles')" v-model="editProject.roles" />
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="cancelEdit">
                                        {{ $t('buttons.cancel') }}
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        {{ $t('buttons.save') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.roles`]="{ item }">
                    <v-chip-group>
                        <v-chip v-for="role in item.roles" :key="role">
                            {{ $t('lists.roles.' + role) }}
                        </v-chip>
                    </v-chip-group>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import projectSelector from '@/components/selectors/simple/project';
import rolesSelector from '@/components/selectors/multiple/roles';
import SortBy from 'lodash/sortBy';

export default {
    name: 'settings-profile-projects',
    data: () => ({
        dialog: false,
        editProject: {
            name: undefined,
            roles: [],
        },
    }),
    created() {
        this.findAllProjects();
    },
    components: {
        projectSelector,
        rolesSelector,
    },
    props: {
        profile: {
            required: true,
        },
    },
    computed: {
        ...mapGetters(['profiles', 'editingProfile', 'projects']),
        formTitle() {
            return this.editProject.id ? this.$t('title.editProject') : this.$t('title.newProject');
        },
        headers() {
            return [
                {
                    text: this.$t('projects.attributes.label'),
                    sortable: true,
                    value: 'label',
                },
                {
                    text: this.$t('projects.roles'),
                    sortable: false,
                    value: 'roles',
                },
                {
                    text: this.$t('profiles.attributes.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        projectsInProfile() {
            return this.editProject.namme === undefined ? this.profile.projects.map((p) => p.name) : [];
        },
        items() {
            if (this.profile) {
                return SortBy(
                    this.profile.projects.map((p) => {
                        return {
                            name: p.name,
                            roles: p.roles,
                            label: this.projectLabel(p.name),
                        };
                    }),
                    ['label'],
                    ['asc'],
                );
            }
            return [];
        },
    },
    methods: {
        ...mapActions(['findAllProjects', 'addProjectToProfile', 'removeProjectToProfile']),
        projectLabel(name) {
            const project = this.projects.find((p) => p.name === name);
            return project ? project.group + ' / ' + project.label : '';
        },
        editItem(item) {
            this.editProject = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            const editProject = {
                profile_id: this.profile.id,
                name: item.name,
            };
            this.removeProjectToProfile(editProject);
        },
        save() {
            this.dialog = false;
            const editProject = {
                profile_id: this.profile.id,
                name: this.editProject.name,
                roles: this.editProject.roles,
            };
            this.addProjectToProfile(editProject);
            this.editProject = {
                name: undefined,
                roles: [],
            };
        },
        cancelEdit() {
            this.dialog = false;
            this.editProject = {
                name: undefined,
                roles: [],
            };
        },
    },
};
</script>
