<template>
    <v-card v-if="profile" flat>
        <v-card-text>
            <v-data-table :headers="headers" :items="items" :items-per-page="-1">
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer />
                        <v-dialog v-model="dialog" max-width="800px">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" small dark v-on="on">{{ $t('buttons.createPack') }}</v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ $t('title.newPack') }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <pack-selector v-model="editPack" :ignored="packsInProfile" type="name" />
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="cancelEdit">
                                        {{ $t('buttons.cancel') }}
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        {{ $t('buttons.save') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import packSelector from '@/components/selectors/simple/pack';
import SortBy from 'lodash/sortBy';

export default {
    name: 'settings-profile-projects',
    data: () => ({
        dialog: false,
        editPack: undefined,
    }),
    created() {
        this.findAllPacks();
    },
    components: {
        packSelector,
    },
    props: {
        profile: {
            required: true,
        },
    },
    computed: {
        ...mapGetters(['profiles', 'editingProfile', 'packs']),
        headers() {
            return [
                {
                    text: this.$t('packs.attributes.label'),
                    sortable: true,
                    value: 'label',
                },
                {
                    text: this.$t('profiles.attributes.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        packsInProfile() {
            return this.editPack === undefined ? this.profile.packs : [];
        },
        items() {
            if (this.profile) {
                return SortBy(
                    this.profile.packs.map((p) => {
                        return {
                            name: p,
                            label: this.packLabel(p),
                        };
                    }),
                    ['label'],
                    ['asc'],
                );
            }
            return [];
        },
    },
    methods: {
        ...mapActions(['findAllPacks', 'addPackToProfile', 'removePackToProfile']),
        packLabel(name) {
            const pack = this.packs.find((p) => p.name === name);
            return pack ? pack.label : '';
        },
        deleteItem(item) {
            const editPack = {
                profile_id: this.profile.id,
                packs: [item.name],
            };
            this.removePackToProfile(editPack);
        },
        save() {
            this.dialog = false;
            const editPack = {
                profile_id: this.profile.id,
                packs: [this.editPack],
            };
            this.addPackToProfile(editPack);
            this.editPack = undefined;
        },
        cancelEdit() {
            this.dialog = false;
            this.editPack = undefined;
        },
    },
};
</script>
