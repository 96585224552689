<template>
    <v-autocomplete
        :autofocus="autofocus"
        v-model="localPack"
        :items="packsItems"
        :outlined="outlined"
        :label="label"
        :readonly="readonly"
        v-on:input="selectPack"
    ></v-autocomplete>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import SortBy from 'lodash/sortBy';
export default {
    name: 'selector-pack',
    data: () => ({
        localPack: undefined,
    }),
    created() {
        if (this.packs.length === 0) {
            this.findAllPacks();
        }
    },
    mounted() {
        this.localPack = this.value;
    },
    props: {
        value: {},
        label: {
            default: '',
        },
        outlined: {
            default: false,
        },
        ignored: {
            default: () => {
                return [];
            },
        },
        readonly: {
            default: false,
        },
        type: {
            default: 'name',
        },
        autofocus: {
            default: false,
        },
    },
    watch: {
        localPack: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localPack = value;
        },
    },
    computed: {
        ...mapGetters(['packs']),
        packsToDisplay() {
            return this.packs.filter((p) => !this.ignored.includes(this.type === 'id' ? p.id : p.name));
        },
        packsItems() {
            return SortBy(
                this.packsToDisplay.map((p) => {
                    return {
                        text: p.label,
                        value: this.type === 'id' ? p.id : p.name,
                    };
                }),
                ['text'],
                ['asc'],
            );
        },
    },
    methods: {
        ...mapActions(['findAllPacks']),
        selectPack(item) {
            this.$emit('update-value', item);
        },
    },
};
</script>
